import { motion } from "framer-motion";

import { Link, useLocation } from "react-router-dom";
import { menuSlide, slide } from "./aminate";
import styled from "@emotion/styled";

const navItems = [
  {
    title: "Home",
    href: "/",
  },
  {
    title: "Work",
    href: "/work",
  },
  {
    title: "Writing",
    href: "https://medium.com/@9toluwalope",
  },
  {
    title: "Contact",
    href: "/contact",
  },
];

interface Props {
  active: VoidFunction;
}

export default function Nav({ active }: Props) {
  let location = useLocation();

  return (
    <>
      {" "}
      <NavEl variants={menuSlide} initial="initial" animate="enter" exit="exit">
        <div className="body">
          <div className="nav">
            {navItems.map((data, index) => {
              return (
                <motion.div
                  custom={index}
                  variants={slide}
                  initial="initial"
                  animate="enter"
                  exit="exit"
                  className={
                    location.pathname === data.href ? "line-through" : ""
                  }
                  onClick={active}
                >
                  <Link
                    key={index}
                    to={data.href}
                    target={data.title === "Writing" ? "_blank" : undefined}
                  >
                    {data.title}
                  </Link>
                </motion.div>
              );
            })}
          </div>
          <div className="footer">
            <p>Github</p>
            <p>Twitter</p>
            <p>LinkedIn</p>
          </div>
        </div>
      </NavEl>
      <Overlay onClick={active} />
    </>
  );
}

const Overlay = styled.div`
  height: calc(100vh - 80px);
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  position: fixed;
  display: block;
  top: 80px;
  left: 0;
  cursor: pointer;
  z-index: 9;
`;

const NavEl = styled(motion.div)`
  /* height: calc(100vh - 80px); */
  width: 100%;
  background-color: rgb(41, 41, 41);
  position: fixed;
  right: 0;
  z-index: 20;
  top: 80px;
  color: white;

  .body {
    box-sizing: border-box;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;

    .nav {
      display: flex;
      flex-direction: column;
      font-size: 32px;
      gap: 6px;

      a {
        text-decoration: none;
        color: white;
        font-weight: 300;
      }
    }
  }

  .footer {
    display: flex;
    width: 100%;
    gap: 20px;
    font-size: 12px;
    margin-top: 32px;
  }
`;
