export const accordionData = [
  {
    header: "Different",
    content:
      "Every project is an opportunity for you to create and produce something that makes people stop and say, “Who the hell did that?!”. i admire your skills and creativity.",
    name: "Busha",
    title: "Senior dev, Bolu",
  },
  {
    header: "Human",
    content:
      "Collaborating with you makes tasks effortless, swift, and highly effective.",
    name: "Ola-Oluwa",
    title: "Frontend Lead, CourierPlus",
  },
  {
    header: "Authentic",
    content:
      "I admire how you bring my designs to life with precision, matching every pixel. Your skills and creativity are definitely taking you far. Keep up the fantastic work!",
    name: "Damilare",
    title: "Senior Designer, Centa",
  },
  {
    header: "Bold",
    content:
      "The way you handle functionality showcases the beauty of my design to my clients.",
    name: "Tobi Mayowa",
    title: "Senior Designer, Zalando",
  },
];
