import styled from "@emotion/styled";
import media from "../styles/media";
import { ReactComponent as Purple } from "../assets/purple.svg";
// import { ReactComponent as Others } from "../assets/others.svg";
import Tolu from "../assets/tolu_new.jpg";
import Strip from "../components/Strip";
import Wrapper from "../components/Wrapper";
import Footer from "../components/Footer";
import Accordion from "../components/Accordion";
import Navbar from "../components/NavBar";
import themes from "../styles/themes";

const Home = () => {
  return (
    <div style={{ backgroundColor: themes.backgroundColors.lightGray }}>
      <Navbar />
      <HomeEl>
        <HeaderContainer>
          <div>
            <img
              src={Tolu}
              width={327}
              height={502}
              className="mt-4 mr-7"
              alt="Tolu's"
            />
          </div>
          <div className="header-text">
            <p>
              CRAFTING THE FUTURE{" "}
              <Purple className="inline text-center w-[60px] h-[60px]  mb-3 mobile:w-[40px] mobile:h-[40px]" />{" "}
              STARTS WITH SHAPING IT CODE BY CODE{" "}
            </p>
          </div>
        </HeaderContainer>
        <Strip
          content={[
            "HTML",
            "CSS",
            "REACT",
            "NEXTJS",
            "JAVASCRIPT",
            "TYPESCRIPT",
            "TAILWIND",
            "STYLED COMPONENTS",
            "ANT DESIGN UI",
            "SHASCN UI",
          ]}
          variant="red"
          aminate
        />

        <Wrapper>
          <div className="expricence">
            <p>
              In the past 3+ years, I have worked with fintech, cryptocurrency
              and logistic companies, providing value to businesses and
              delighting users with my knowledge and skills, implementing clean
              and user friendly design. With the knowledge gathered from my
              physics and British Computer Society (BCS) degrees. <br />
              <br />{" "}
              <em>
                -- I don’t like to define myself by the work I’ve done. I define
                myself by the work I want to do.
              </em>
            </p>
          </div>
          <hr className="border-black mx-[40px] mobile:mx-[20px]" />

          <div className="pt-[24px] px-[40px] pb-[120px] mobile:px-[20px]">
            <p className="text-[16px] font-medium font-pregular">
              SOME BRANDS I’VE WORKED WITH
            </p>
            <div className="flex mt-[24px] mobile:flex-col font-rmedium">
              <div className="w-[524px]"></div>
              <div className="max-w-[720px] text-[64px] font-medium leading-tight mobile:text-[40px]">
                CourierPlus <span className="font-thin">/</span> Busha{" "}
                <span className="font-thin">/</span> Afriex{" "}
                <span className="font-thin">/</span> Centa
              </div>
            </div>
          </div>

          <hr className="border-black mx-[40px] mobile:mx-[20px]" />

          <div className="pt-[24px] px-[40px] pb-[120px] mobile:px-[20px]">
            <p className="text-[16px] font-medium font-pregular">TECH STACKS</p>
            <div className="flex mt-[24px] mobile:flex-col">
              <div className="w-[524px]"></div>
              <div className="max-w-[720px] text-[28px] font-medium font-rmedium leading-tight mobile:text-[40px]">
                HTML <span className="font-thin">/</span> CSS{" "}
                <span className="font-thin">/</span> REACT{" "}
                <span className="font-thin">/</span> NEXTJS{" "}
                <span className="font-thin">/</span> JAVASCRIPT{" "}
                <span className="font-thin">/</span> TYPESCRIPT{" "}
              </div>
            </div>
          </div>

          <hr className="border-black mx-[40px] mobile:mx-[0px]" />

          <div className="pt-[24px] px-[40px] pb-[120px] mobile:px-[0px]">
            <p className="text-[16px] font-medium mobile:px-[20px] font-pregular">
              TESTIMONIALS
            </p>
            <Accordion />
          </div>
        </Wrapper>
        <Strip
          content="→ VIEW MY WORKS"
          size="big"
          aminate
          url="work"
          variant="orange"
        />
        {/* <Wrapper className="smDesktop:hidden">
        <Others />
      </Wrapper> */}
        <Footer variant="red" />
      </HomeEl>
    </div>
  );
};

export default Home;

const HomeEl = styled.div`
  .expricence {
    font-family: "Roobert-Medium";
    max-width: 974px;
    padding: 60px 0px 120px 40px;
    font-size: 38px;
    font-weight: 500;
    line-height: 48px; /* 126.316% */
    letter-spacing: -0.6px;

    ${media.mobile} {
      padding: 60px 20px 120px 20px;
      font-size: 30px;
      line-height: 36px; /* 120% */
      letter-spacing: -0.2px;
    }
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 55px;
  padding-bottom: 65px;

  ${media.smallDesktop} {
    flex-direction: column-reverse;
    padding-top: 35px;
    padding: 44px 20px;
    padding-bottom: 0px;
  }

  .header-text {
    max-width: 680px;
    font-size: 80px;

    font-weight: 700;
    line-height: 72px;
    letter-spacing: -4px;

    ${media.mobile} {
      font-size: 42px;
      font-weight: 900;
      line-height: 54px;
      letter-spacing: -2.6px;
    }
  }
`;
