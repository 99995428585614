import * as React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Work from "./pages/Work";
import Contact from "./pages/Contact";
import "./styles/fonts/Roobert-Regular.otf";
import "./styles/fonts/Roobert-Medium.otf";
import "./styles/fonts/Roobert-SemiBold.otf";
import "./styles/fonts/Roobert-Bold.otf";
import "./styles/fonts/PPSupplyMono-Regular.otf";
import "./styles/fonts/PPSupplyMono-Light.ttf";

function App() {
  return (
    <div>
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route index path="/work" element={<Work />} />
        <Route index path="/contact" element={<Contact />} />
      </Routes>
    </div>
  );
}

export default App;
