import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import Nav from "./components/Nav";

export default function MobileMenu() {
  const [isActive, setIsActive] = useState(false);

  return (
    <>
      <div
        onClick={() => {
          setIsActive(!isActive);
        }}
      >
        {isActive ? "X-Menu" : "Menu"}
      </div>
      <AnimatePresence mode="wait">
        {isActive && <Nav active={() => setIsActive(!isActive)} />}
      </AnimatePresence>
    </>
  );
}
