import styled from "@emotion/styled";
import Wrapper from "../components/Wrapper";
import Footer from "../components/Footer";
import media from "../styles/media";
import Navbar from "../components/NavBar";
import themes from "../styles/themes";

const Work = () => {
  return (
    <div style={{ backgroundColor: themes.backgroundColors.lightGreen }}>
      <Navbar />
      <Wrapper>
        <HeaderContainer>
          <p className="text-header">SELECTED WORK</p>
          <p className="sub-header-text font-plight">
            The works here have been abstracted to protect confidential
            information. If you need to see the full, detailed version, please
            contact me at work@toluwalope.com
          </p>
        </HeaderContainer>
        <SectionContainer></SectionContainer>
      </Wrapper>
      <Footer />
    </div>
  );
};

export default Work;

const HeaderContainer = styled.div`
  padding: 99px 0px 120px 40px;
  max-width: 974px;

  ${media.mobile} {
    padding: 99px 0px 120px 20px;
  }

  .text-header {
    font-size: 105px;
    font-style: normal;
    font-weight: 700;
    line-height: 92px; /* 87.619% */
    letter-spacing: -4px;
    text-transform: uppercase;
    margin-bottom: 16px;

    ${media.mobile} {
      font-size: 52px;
      font-weight: 700;
      line-height: 54px;
      letter-spacing: -2.6px;
    }
  }

  .sub-header-text {
    max-width: 900px;
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.36px;
  }
`;

const SectionContainer = styled.div``;
