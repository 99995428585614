import styled from "@emotion/styled";
import media from "../styles/media";
import { Link } from "react-router-dom";
import { ReactTyped } from "react-typed";
import themes from "../styles/themes";
import { GoArrowUpRight } from "react-icons/go";
import MobileMenu from "./hambugerMenu";

const Navbar = () => {
  return (
    <NavbarContainer>
      <div>
        <Link to="/">
          <p>
            Toluwalope Akindunjoye <br />
            <ReactTyped
              strings={["Software Developer"]}
              loop
              typeSpeed={40}
              backSpeed={50}
            />
          </p>
        </Link>
      </div>
      <NavbarLinks>
        <Link to="/work">
          <p>Work</p>
        </Link>
        <div>
          <Link to="https://medium.com/@9toluwalope" target="_blank">
            <p className="flex ">
              Writing <GoArrowUpRight className="p-0 m-0" />
            </p>
          </Link>
        </div>

        <Link to="/contact">
          <p>Contact</p>
        </Link>
      </NavbarLinks>
      <NewProject>
        <p>
          Currently learning <br />{" "}
          <ReactTyped
            strings={["Golang", "React Native"]}
            loop
            typeSpeed={40}
            backSpeed={50}
          />
        </p>
      </NewProject>
      <MobileLinks>
        <MobileMenu />
      </MobileLinks>
    </NavbarContainer>
  );
};

export default Navbar;

const NavbarContainer = styled.div`
  position: sticky;
  top: 0;
  font-family: "PPSupplyMono-Light";
  background-color: inherit;
  border-bottom: 2px solid white;
  height: 80px;
  padding: 20px 40px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;

  a {
    text-decoration: none;
    color: ${themes.colors.black};
  }

  ${media.mobile} {
    padding: 20px 20px;
  }
`;

const NavbarLinks = styled.div`
  display: flex;
  width: 40%;
  justify-content: space-between;
  text-align: center;

  ${media.smallDesktop} {
    display: none;
  }
`;

const NewProject = styled.div`
  ${media.smallDesktop} {
    display: none;
  }
`;

const MobileLinks = styled.div`
  display: none;
  text-decoration: underline;
  ${media.smallDesktop} {
    display: block;
  }
`;
