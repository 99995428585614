import { AppThemeConfig } from "../modals/appThemes";

const themes: AppThemeConfig = {
  colors: {
    black: "#000",
  },
  backgroundColors: {
    purple: "#cbb8e3",
    yellow: "#FFEB00",
    orange: "#e3b8bb",
    lightGray: "#EAE3DC",
    lightGreen: "#BBE3B8",
    white: "#FFFFFF",
    red: "#FC2222",
    deepGreen: "#007A00",
  },
  shadows: {
    dark: "",
  },
};

export default themes;
